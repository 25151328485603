import React, { useState } from "react";
import plans from "constants/plans";
import { PricingPlan } from "components";
import { SelectionSwitch } from "components/UI/SelectionSwitch";
import { Section } from "components/UI/Section";
import { PlansGrid } from "components/UI/PlansGrid";

function Pricing() {
  const [selectedBillingCycle, setBillingCycle] = useState<{
    value: "month" | "year";
    label: React.ReactNode;
  }>({
    value: "month",
    label: "monthly",
  });

  return (
    <Section
      id="pricing"
      title="Our Plans and Pricing"
      background="light"
      description={
        <>
          <span>
            Don&apos;t waste time and costs on a dedicated infrastructure.
          </span>
          <br />
          <span>
            Leave it to us and focus on what is vital for you - building your
            applications.
          </span>
        </>
      }
    >
      <style jsx>{`
        .wrapper {
          display: flex;
          width: 100%;
          flex-direction: column;
        }
        .billing-cycle-toggle {
          display: flex;
          margin-bottom: 30px;
          justify-content: flex-end;
        }
      `}</style>
      <div className="wrapper">
        <div className="billing-cycle-toggle">
          <SelectionSwitch
            options={[
              {
                value: "month",
                label: "Monthly",
              },
              {
                value: "year",
                label: "Yearly",
              },
            ]}
            // @ts-expect-error TODO in SelectionSwitch
            onChange={option => setBillingCycle(option)}
            value={selectedBillingCycle}
          />
        </div>

        <PlansGrid>
          {plans.map((plan, i) => (
            <PricingPlan
              predefinedPlan={plan}
              billingCycle={selectedBillingCycle.value}
              key={i}
            />
          ))}
        </PlansGrid>
      </div>
    </Section>
  );
}

export { Pricing };
