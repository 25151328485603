import React from "react";
import { Container } from "../Container";
import { COLOR, FONT_SIZE } from "constants/styles";

const getTitleColor = (bgType: Props["background"]) => {
  switch (bgType) {
    case "dark":
      return COLOR.TEXT_LIGHT;
    case "light":
      return COLOR.TEXT_PRIMARY;
    case "transparent":
    default:
      return COLOR.TEXT_PRIMARY;
  }
};
const getDescriptionColor = (bgType: Props["background"]) => {
  switch (bgType) {
    case "dark":
      return COLOR.TEXT_LIGHT;
    case "light":
      return COLOR.TEXT_SECONDARY;
    case "transparent":
    default:
      return COLOR.TEXT_SECONDARY;
  }
};

const getBackgroundColor = (bgType: Props["background"]) => {
  switch (bgType) {
    case "dark":
      return `radial-gradient(#3c3351 0%, ${COLOR.BG_LANDING_DARK} 70%)`;
    case "white":
      return COLOR.BG_WHITE;
    case "light":
      return COLOR.BG_LANDING_LIGHT;
    case "transparent":
    default:
      return "transparent";
  }
};

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, "title"> {
  id: string;
  title: React.ReactNode;
  description?: React.ReactNode;
  illustration?: React.ReactNode;
  background?: "transparent" | "white" | "light" | "dark";
  children: React.ReactNode;
}

export const Section = ({
  id,
  title,
  illustration,
  description,
  background,
  children,
  ...rest
}: Props) => {
  return (
    <section className="section-wrapper" id={id} {...rest}>
      <style jsx>{`
        .section-wrapper {
          display: flex;
          width: 100%;
          flex-direction: column;
          background: ${getBackgroundColor(background)};
          padding: 5rem 0rem;
        }
        h3.title {
          color: ${getTitleColor(background)};
          font-size: ${FONT_SIZE.XXL};
          font-weight: 500;
          padding-bottom: 20px;
          text-align: center;
        }

        .illustration {
          display: flex;
          justify-content: center;
          margin-bottom: 48px;
        }

        .description {
          text-align: center;
          font-size: ${FONT_SIZE.LG};
          color: ${getDescriptionColor(background)};
          margin-bottom: 4rem;
        }

        .section-header {
          display: flex;
          flex-direction: column;
        }
        .section-content {
          display: flex;
          width: 100%;
          justify-content: center;
        }
      `}</style>

      <Container content noBackground>
        <div className="section-header">
          {illustration && <div className="illustration">{illustration}</div>}
          <h3 className="title">{title}</h3>
          {description && <p className="description">{description}</p>}
        </div>
        <div className="section-content">{children}</div>
      </Container>
    </section>
  );
};
