import WorldMap from "components/dashboard/WorldMap";

// layout
export { MainLayout } from "components/layout/MainLayout";
export { Navbar } from "components/layout/Navbar";
export { Footer } from "components/layout/Footer";
export { IconCopyToClipboard } from "components/layout/IconCopyToClipboard";

// sections
export { Introduction } from "components/layout/sections/Introduction";
export { FAQ } from "components/layout/sections/FAQ";
export { Pricing } from "components/layout/sections/Pricing";

// auth
export { EmailSignIn } from "components/auth/EmailSignIn";
export { GoogleSignIn } from "components/auth/GoogleSignIn";
export { GitLabSignIn } from "components/auth/GitLabSignIn";
export { GithubSignIn } from "components/auth/GithubSignIn";

// payments
export { Plan } from "components/plans/Plan";
export { PricingPlan } from "components/plans/PricingPlan";

// dashboard
export { AddProjectForm } from "components/dashboard/AddProjectForm";
export { Projects } from "components/dashboard/Projects";
export { Examples } from "components/dashboard/Examples";
export { HistoryGraph } from "components/dashboard/HistoryGraph";
export { DashboardStats } from "components/dashboard/DashboardStats";
export { DashboardHeader } from "components/dashboard/DashboardHeader/index";
export { SubmitAddPlaceholder } from "components/dashboard/SubmitAddPlaceholder";
export { DashboardLayout } from "components/dashboard/DashboardLayout";
export { Box } from "components/dashboard/Box";
export { SmallBox } from "components/dashboard/SmallBox";
export { ProjectIdBox } from "components/dashboard/ProjectIdBox";
export { ButtonDeleteWithConfirm } from "components/dashboard/ButtonDeleteWithConfirm";
export { LockedOrEmptyBox } from "components/dashboard/LockedOrEmptyBox";
export { WorldMap };
export { WebhookForm } from "components/dashboard/WebhookForm/WebhookForm";
export { RowPlaceholder } from "components/dashboard/RowPlaceholder/RowPlaceholder";

// UI
export { ErrorComponent } from "components/UI/Error";
export { Button } from "components/UI/Button";
export { DeleteButtonWithConfirmation } from "components/UI/DeleteButtonWithConfirmation";
export { ShinyBanner } from "components/UI/ShinyBanner";
export { Badge } from "components/UI/Badge";
export { Card } from "components/UI/Card";
export { Container } from "components/UI/Container";
export { Breadcrumbs } from "components/UI/Breadcrumbs";
export { ProjectBox } from "components/UI/ProjectBox";
export { Pagination } from "components/UI/Pagination";
export { Alert } from "components/UI/Alert";
export { Switch } from "components/UI/Switch";
export { Spinner } from "components/UI/Spinner";
export { Announcement } from "components/UI/Announcement";
export { RoleBadge } from "components/UI/RoleBadge";
export { TableColHeading } from "components/UI/TableColHeading";
export { HoverableIcon } from "components/UI/HoverableIcon";
export { Label } from "components/UI/Label";
export { CookieConsent } from "./UI/CookieConsent";
