import { COLOR } from "constants/styles";
import React from "react";

export interface SelectionSwitchOption {
  value: string;
  label: React.ReactNode;
}

interface Props {
  options: SelectionSwitchOption[];
  // TODO: value should be generic extending options type
  value: SelectionSwitchOption;
  onChange: (option: SelectionSwitchOption) => void;
}

export const SelectionSwitch = ({ options, value, onChange }: Props) => {
  return (
    <>
      <style jsx>{`
        .switch-wrapper {
          display: flex;
        }

        .option {
          display: flex;
          padding: 8px 24px;
          cursor: pointer;
          border: 1px solid ${COLOR.BORDER_PRIMARY};
          transition: 0.3s;
        }

        .option-label {
          font-weight: 500;
          color: ${COLOR.TEXT_PRIMARY};
        }

        .option-active {
          background: ${COLOR.PRIMARY};
          border-color: ${COLOR.PRIMARY};
        }

        .option-active .option-label {
          color: ${COLOR.TEXT_LIGHT};
        }

        .option:first-of-type {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        .option:last-of-type {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }

        .option + .option {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          border-left: 0px solid ${COLOR.BORDER_PRIMARY};
        }
      `}</style>
      <div className="switch-wrapper">
        {options.map(option => {
          return (
            <div
              key={option.value}
              onClick={() => onChange(option)}
              className={
                option.value === value.value ? "option option-active" : "option"
              }
            >
              <div className="option-label">{option.label}</div>
            </div>
          );
        })}
      </div>
    </>
  );
};
