import { BREAKPOINT } from "constants/styles";
import React from "react";

export const PlansGrid = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <style jsx>{`
        .plans-grid {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 30px;
        }

        @media (max-width: ${BREAKPOINT.LG}px) {
          .plans-grid {
            grid-template-columns: 1fr 1fr;
          }
        }

        @media (max-width: ${BREAKPOINT.XS}px) {
          .plans-grid {
            grid-template-columns: 1fr;
          }
        }
      `}</style>

      <div className="plans-grid">{children}</div>
    </>
  );
};
